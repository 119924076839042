<template>
  <div>
    <div class="wrap2">
      <div class="nav">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/item-list' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ name: 'job' }">作业管理</el-breadcrumb-item>
          <el-breadcrumb-item>创建作业主题</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="create_wrap">
        <div class="top">
          <div class="create_title">创建作业主题</div>
        </div>
        <div class="content">
          <el-form style="margin-bottom:66px">
            <el-form-item label="主题类型:">
              <el-radio-group v-model="themetype">
                <el-radio :label="1">作业</el-radio>
                <el-radio :label="2">测试</el-radio>
                <el-radio :label="3">问卷</el-radio>
                <el-radio :label="4">普通话测试</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="主题名称:">
              <el-row>
                <el-col :span="12">
                  <el-input placeholder="请输入主题名称" :rows="4" maxlength=60 v-model="themename" @input="changethemename" />
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item label="主题封面">
              <el-row>
                <el-col :span="5">
                  <div class="default">
                    <div>
                      <img src="@/assets/task-cover.png" />
                    </div>
                    <span>默认封面</span>
                  </div>
                </el-col>
                <el-col :span="5">
                  <div class="upload">
                    <el-upload :limit="3" action="https://jsonplaceholder.typicode.com/posts/">
                      <img src="@/assets/upload.png" />
                      <div slot="tip" class="el-upload__tip">点击上传封面</div>
                    </el-upload>
                  </div>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item label="绑定学科:">
              <el-select v-model="subjectVal" placeholder="请选择">
                <el-option v-for="item in subjectlist" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="主题绑定:">
              <el-radio-group v-model="themebind">
                <el-radio :label="1">课程</el-radio>
                <el-radio :label="2">活动</el-radio>
                <el-radio :label="3">项目</el-radio>
                <el-radio :label="4">不绑定</el-radio>=
              </el-radio-group>
            </el-form-item>
            <el-form-item :label="bindVal">
              <el-select v-model="courseVal" placeholder="请选择" v-if="
                  themebind == 1 ||
                    themebind == 2 ||
                    themebind == undefined ||
                    themebind == ''
                ">
                <el-option v-for="item in courselist" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
              <el-select v-model="groupVal" v-if="themebind == 3" style="margin-rigth:20px" placeholder="请选择分期">
                <el-option v-for="item in grouplist" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
              <el-cascader :options="projectlist" :props="{ checkStrictly: true, value: 'id', label: 'name' }" clearable
                v-if="themebind == 3" v-model="projectVal" style="margin-left:20px"></el-cascader>
            </el-form-item>
            <el-form-item label="用户组:" v-if="themebind == 3">
              <el-cascader :options="usergroup" :props="{ multiple: true, value: 'id' }" clearable filterable
                style="margin-left:14px" v-model="userVal" ref="cascader" @change="userGroupChange"></el-cascader>
            </el-form-item>
          </el-form>
          <div class="btn">
            <div @click="cancel">取消</div>
            <div @click="submit">确定</div>
          </div>
        </div>
      </div>
      <!-- <Footer></Footer> -->
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        bindVal: "", //绑定**，默认显示不绑定
        themetype: 1, //主题类型 1-作业 2-测试 3-问卷 4-普通话测试
        themename: "", //主题名称
        themebind: 4, //主题绑定的值 1-课程 2-活动 3项目 4-不绑定
        subjectVal: "", //绑定学科对应的值
        courseVal: "", //绑定的课程或者活动对应的值
        subjectlist: [], //绑定学科列表
        courselist: [], //绑定课程|| 绑定活动列表
        projectVal: [], //绑定项目
        projectlist: [], //项目列表
        usergroup: [], //用户组列表
        grouplist: [], //期次列表
        groupVal: "", //选中的期次值
        userVal: [], //用户组选择的值
        userList: [],
        oldData: {}, //上次编辑的旧数据，用于回显数据
      };
    },
    methods: {
      //监听用户组变化
      userGroupChange() {
        let userGroups = this.$refs["cascader"].getCheckedNodes();
        this.userList = userGroups;
      },
      changethemename(value) {
        this.themename = value;
      },

      submit() {
        //判断主题名称必填项
        if (!this.themename.trim()) {
          this.$alert("请填写主题名名称", "提示");
        } else if (this.bindVal == "绑定课程" && !this.courseVal) {
          this.$alert("请选择绑定的课程名称", "提示");
        } else if (this.bindVal == "绑定活动" && !this.courseVal) {
          this.$alert("请选择绑定的活动名称", "提示");
        } else if (this.bindVal == "绑定项目" && !this.groupVal) {
          this.$alert("请选择绑定的项目", "提示");
        } else if (this.bindVal == "绑定项目" && this.projectVal.length == 0) {
          this.$alert("请选择绑定的项目", "提示");
        } else if (this.bindVal == "绑定项目" && this.userVal.length == 0) {
          this.$alert("请选择用户组", "提示");
        } else {
          this.$confirm("是否提交该作业主题？", "提示", {
              confirmButtonText: "提交",
              cancelButtonText: "取消",
              type: "success"
            })
            .then(() => {
              let userArr = [];
              this.userList.map(item => {
                userArr.push(item.value);
              });

              if (this.themebind == 4) {
                this.courseVal = "";
              }
              if (this.themebind == 3) {
                this.courseVal = this.projectVal[this.projectVal.length - 1];
              }
              let params = {
                projectId: this.$route.query.projectId, //二级项目id
                themeName: this.themename, //主题名称
                themeType: this.themetype, //主题类型
                theme_cover: null, //主题封面
                subjectId: this.subjectVal, //绑定学科的id
                bindingId: this.courseVal, //绑定课程或者活动的id
                themeBinding: this.themebind, //主题绑定的id
                projectPeriodId: this.groupVal, //项目期次id
                projectSublevelId: this.projectVal[this.projectVal.length - 1], //子集项目id
                userGroupId: userArr, //用户组id
                id: this.$route.query.id, //主题id
                createId: this.$route.query.createId //创建者&修改者id
              };
              console.log(params);
              if (this.$route.query.edit) {
                this.$Api.Form.updateTheme(params)
                  .then(res => {
                    // console.log(res);
                    this.$message({
                      type: "success",
                      message: "修改成功"
                    });
                    this.$router.push({
                      path: "/home/manage/job"
                    });
                  })
                  .catch(err => {
                    console.log(err);
                    this.$message({
                      type: "error",
                      message: "取消修改"
                    });
                  });
              } else {
                this.$Api.Form.addTheme(params)
                  .then(res => {
                    console.log(res);
                    this.$message({
                      type: "success",
                      message: "提交成功"
                    });
                    this.$router.push({
                      path: "/design",
                      query: {
                        themeId: res.data,
                        createId: this.$route.query.createId,
                        themetype: this.themetype
                      }
                    });
                  })
                  .catch(err => {
                    console.log(err);
                    this.$message({
                      type: "error",
                      message: "提交失败"
                    });
                  });
              }
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "取消成功"
              });
            });
        }
      },
      cancel() {
        this.$confirm("确认取消创建作业主题？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {});
      },
      //获取绑定学科列表
      getsubject() {
        this.$Api.Form.getSubject()
          .then(res => {
            this.subjectlist = res.data;
          })
          .catch(err => {
            console.log(err);
          });
      },

      //获取绑定课程列表
      getcourse() {
        this.$Api.Form.getCourse(this.$route.query.projectId)
          .then(res => {
            this.courselist = res.data;
          })
          .catch(err => {
            console.log(err);
          });
      },

      //获取绑定活动列表
      getactive() {
        this.$Api.Form.getActive(this.$route.query.projectId)
          .then(res => {
            this.courselist = res.data;
          })
          .catch(err => {
            console.log(err);
          });
      },

      //获取绑定项目分期列表
      getproject() {
        this.$Api.Form.getPeriod(this.$route.query.projectId)
          .then(res => {
            // console.log(res)
            this.grouplist = res.data;
          })
          .catch(err => {
            console.log(err);
          });
      },
      //创建/编辑作业主题
      viewTheme() {
        //判断用户是否点击编辑按钮进入，如果是编辑则表单回填上次数据
        if (this.$route.query.edit) {
          this.$Api.Form.getThemeInfo(this.$route.query.id)
            .then(value => {
              console.log('上次编辑的数据', value);
              this.oldData = value.data;
              this.themetype = value.data.themeType; // 主题类型
              this.themename = value.data.themeName; // 主题名称
              this.subjectVal = value.data.subjectId; // 绑定学科对应的值
              this.themebind = value.data.themeBinding; // 主题绑定的值 1-课程 2-活动 3项目 4-不绑定
              // this.courseVal = value.data.bindingId;
            })
            .catch(reason => {
              console.log(reason);
            });
        } else {
          (this.bindVal = ""), //默认显示绑定课程
          (this.themetype = 1), //主题类型
          (this.themebind = 4), //主题绑定
          (this.subjectVal = ""), //绑定学科
          (this.courseVal = ""),
          // (this.courseId = ""), //绑定课程的id
          // (this.activityId = ""), //绑定活动的id
          (this.groupVal = ""), //选中的期次值
          (this.themename = ""), //主题名称
          (this.subjectlist = []), //绑定学科列表
          (this.courselist = []), //绑定课程|| 绑定活动列表
          (this.projectVal = []), //绑定项目
          (this.projectlist = []), //项目列表
          (this.usergroup = []), //用户组列表
          (this.grouplist = []), //期次列表
          (this.userVal = []); //用户组选择的值
        }
      }
    },
    watch: {
      //监听主题绑定
      themebind(newVal, oldVal) {
        this.courseVal = "";
        this.projectVal = [];
        if (newVal == 1) {
          this.bindVal = "绑定课程";
          this.courseVal = this.oldData.bindingId
          this.getcourse();
        } else if (newVal == 2) {
          this.bindVal = "绑定活动";
          this.courseVal = this.oldData.bindingId
          this.getactive();
        } else if (newVal == 3) {
          this.bindVal = "绑定项目";
          // this.userVal = this.oldData.userGroupId
          // this.projectVal = this.oldData.projectId
          this.getproject();
        } else {
          this.bindVal = "";
        }
      },
      //监听选择期次
      groupVal(newVal, oldVal) {
        console.log("新值--", newVal)
        console.log("旧值--", oldVal)
        let proArr = this.grouplist.filter(item => {
          return item.id == newVal;
        });
        this.projectlist = proArr[0].children;
      },
      //监听项目绑定的值，用于获取项目下的用户组
      projectVal(newVal) {
        if (this.themebind == 3 && this.projectVal[0]) {
          this.$Api.Form.getUserGroup(newVal[newVal.length - 1])
            .then(res => {
              this.usergroup = res.data;
            })
            .catch(err => console.log(err));
        }
      }
    },
    computed: {},
    activated() {
      this.viewTheme();
    },
    mounted() {
      this.getsubject();
    }
  };
</script>

<style lang="scss" scoped>
  .wrap2 {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: #f9f9f9;
    color: #333333;
    font-size: 16px;

    .nav {
      width: 1200px;
      margin: 0 auto 31px;
      padding-top: 51px;
    }

    .create_wrap {
      width: 1200px;
      margin: 0 auto 100px;
      background: #ffffff;
      box-sizing: border-box;
      padding-bottom: 113px;

      .top {
        width: 100%;
        height: 77px;
        border-bottom: 1px solid #f1f1f1;

        .create_title {
          font-size: 22px;
          font-weight: bold;
          color: #2373ec;
          margin-left: 45px;
          width: 160px;
          height: 100%;
          text-align: center;
          line-height: 77px;
          border-bottom: 5px solid #2373ec;
        }
      }

      .content {
        box-sizing: border-box;
        padding-left: 120px;
        margin-top: 30px;

        .default {
          display: flex;
          flex-direction: column;
          align-items: center;

          div {
            width: 100%;
            height: 125px;
            background: cornflowerblue;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .upload {
          margin-left: 12px;
          width: 100%;
          height: 125px;
          background: #eff0f4;
          display: flex;
          align-items: center;
          justify-content: space-around;
        }

        .btn {
          display: flex;
          align-items: center;

          div:first-child {
            width: 161px;
            height: 42px;
            border: 1px solid #f9f9f9;
            background: #ffffff;
            box-shadow: 0px 5px 13px 0px rgba(22, 115, 255, 0.28);
            border-radius: 5px;
            color: #2774ee;
            font-size: 18px;
            text-align: center;
            line-height: 42px;
            margin: 0 122px 0 81px;
          }

          div:last-child {
            width: 161px;
            height: 42px;
            border: 1px solid #f9f9f9;
            background: linear-gradient(90deg, #2272ec, #3a7bf6);
            box-shadow: 0px 5px 13px 0px rgba(22, 115, 255, 0.28);
            border-radius: 5px;
            font-size: 18px;
            color: #ffffff;
            text-align: center;
            line-height: 42px;
          }
        }
      }
    }
  }
</style>